@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik+Moonrocks&display=swap');
body{
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.logo{
  font-weight: 900;
  font-size: x-large;
}
nav a.active {
  color:#023047;
  text-decoration:underline  #499ede 3px;
  
}
.hamburger{
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}
.hamburger-top,.hamburger-middle,.hamburger-bottom{
  position: absolute;
  top:0;
  left: 0;
  width: 24px;
  height: 2px;
  background: black;
  transform: rotate(0);
  transition: all 0.5s;

}
.hamburger-middle{
  transform:translateY(7px)
}
.hamburger-bottom{
  transform:translateY(14px)
}
.open{
  transform: rotate(90deg);
  transform: translateY(0px);
}
.open .hamburger-top{
  transform: rotate(45deg) translateY(6px) translateX(6px);
}
.open .hamburger-middle{
  display: none;
}
.open .hamburger-bottom{
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}
.hero-img img{
  width: 90%;
 
}
.start-btn{
  background: #499ede;
    border-radius: 8px;
    box-sizing: border-box!important;
    color: #fff;
    display: inline-block;
    font-family: Montserrat,sans-serif;
    font-size: 18px;
    font-weight: 700!important;
    line-height: 22px;
    min-width: 185px;
    padding: 11px 30px;
    position: relative;
    text-align: center;
    transition: color .5s ease,background .5s ease,border .5s ease;
}

.testimonial-card{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  /* background: rgb(255, 244, 229); */
}
.nav-item {
  position: relative;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-width: 160px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-content .dropdown-item {
  color: black;
  padding: 12px 26px;
  text-decoration: none;
  display: block;
}

.dropdown-content .dropdown-item:hover {
  background-color: #f1f1f1;
}

.nav-item:hover .dropdown-content {
  display: block;
}
.module-card{
  box-shadow: 0 4px 8px 0 rgba(1,0,0,0.5);
  transition: 0.3s;
  /* background: rgb(255, 244, 229); */
}
.resource-card{
  box-shadow: 0 4px 8px 0 rgba(1,0,0,0.5);
  transition: 0.3s;
  border-radius: 18px;
  padding: 20px 12px;
}
/* Add some padding inside the card container */
.card-container {
  padding: 2px 16px;
}
.choose-us-container.mt-6 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 0.6fr));
  gap: 4rem;
}

@media (max-width: 768px) {
  .choose-us-container.mt-6{
    display: flex;
    flex-direction: column;
    gap: 2rem; 
    place-items: center;
  }
}
.testimonials_container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 0.6fr)); /* Adjust size as needed */
  gap: 4rem; /* Adjust space between cards */
}

@media (max-width: 768px) {
  .testimonials_container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
}

#card-img {
  width: 500px; /* Adjust width as needed */
  height: 300px;
  object-fit: cover; /* Ensure the image covers the entire container */
}

#card-button:hover {
  svg {
    width: 2rem;
    transition-delay: 0.25s;
  }
}

#cards:hover {
  background:#499ede!important;
  transition: 1s;
  color: #ffffff !important;
}



#cards:hover a, #cards:hover h5, #cards:hover p {
  color: #ffffff !important;
}
.flyer-div{
  position: relative; 
  width: 100%; height: 0; padding-top: 100.0000%;
padding-bottom: 0; 
box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); 
margin-top: 1.6em;
 margin-bottom: 0.9em; 
 overflow: hidden;
border-radius: 8px; 
will-change: transform;
}
iframe{
  position: absolute;
   width: 100%; height: 100%; 
   top: 0; 
   left: 0; 
   border: none; 
   padding: 0;
   margin: 0;}
